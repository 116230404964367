import React, { useRef, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import EncourageFanLetter from '@/assets/img/home/img_EncourageFanLetter.webp'
import { Colors } from '@/constants/styles/color'
import { getSellerApps } from '@/pages/api/payment'
import { event as gevent } from '@/pages/api/gtag'

const StyledPopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledPopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledCard = styled.div`
  padding: 8px 8px 16px;
  background: white;
  border-radius: 16px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -1px -1px 8px rgba(0, 0, 0, 0.25);
  max-width: 90%;
  margin: 0 auto;

  @media (min-width: 480px) {
    max-width: 440px;
  }
`

const StyledPopupImage = styled.img`
  height: auto;
  width: 100%;
  max-width: 480px;
`

const StyledText = styled.p`
  text-align: center;
  margin: 16px 0;
`

const StyledButton = styled.button`
  background: ${Colors.BLACK};
  width: 80%;
  margin: 16px auto 0;
  max-height: 72px;
  padding: 12px;
  border-radius: 24px;
  border: none;
  color: ${Colors.WHITE};
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButtonText = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: normal;
  font-size: 16px;
`

const StyledCloseButton = styled.button`
  width: 88px;
  height: 40px;
  margin: 24px auto;
  font-size: 12px;
  padding: 12px 16px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  color: ${Colors.BLACK};
  background-clip: content-box, border-box;
  background: ${Colors.WHITE};
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -1px -1px 8px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: ${Colors.WHITE};
  }
`

interface Props {
  creatorUid: string
  creatorId: string
  isCurrentPopup: boolean
  sendPopupVisibleToParent: (visible: boolean) => void
}

const FanLetterEncouragementPopup = ({
  creatorUid,
  creatorId,
  isCurrentPopup,
  sendPopupVisibleToParent,
}: Props) => {
  const [visible, setVisible] = useState(false)
  const handleVisibleChanged = (visible: boolean) => {
    setVisible(visible)
    sendPopupVisibleToParent(visible)
  }
  const [fanLetterUrl, setFanLetterUrl] = useState<string | null>(null)

  // StyledCardの外側をクリックしたら閉じる
  const refCard = useRef<HTMLDivElement>(null)
  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (refCard.current && !refCard.current.contains(event.target as Node)) {
      handleVisibleChanged(false)
    }
  }

  const handleClick = async () => {
    gevent({
      action: 'ct_fanletter_popup',
      category: 'purchase',
    })

    const a = document.createElement('a')
    a.href = fanLetterUrl!
    a.target = '_parent'
    a.click()

    handleVisibleChanged(false)
  }

  useEffect(() => {
    getSellerApps(creatorUid).then(res => {
      // デジタルファンレターのリンクを取得
      const fanLetterApp = res.find((app: MiniAppInfo) => app.app_url_key === 'dfl')

      // ポップアップの制御（24時間に1回表示する）
      const currentTime = Date.now()
      const lastDisplayTime = localStorage.getItem('lastFanLetterPopupDisplayTime')
      const oneDayAgo = currentTime - 24 * 60 * 60 * 1000

      if (fanLetterApp && (!lastDisplayTime || Number(lastDisplayTime) < oneDayAgo)) {
        setFanLetterUrl(
          `/@${creatorId}/apps/${fanLetterApp.app_url_key}${fanLetterApp.seller_app_url_key}`,
        )
        localStorage.setItem('lastFanLetterPopupDisplayTime', String(currentTime))
        handleVisibleChanged(true)
      }
    })
  }, [])

  if (!isCurrentPopup || !visible) return null
  return (
    <StyledPopupWrapper
      onClick={handleClickOutside}
      onLoad={() =>
        gevent({
          action: 'imp_fanletter_popup',
          category: 'purchase',
        })
      }
    >
      <StyledPopupContent>
        <StyledCard ref={refCard}>
          <div className="rounded-[14px] overflow-hidden">
            <StyledPopupImage
              src={EncourageFanLetter.src}
              alt={'ファンミでファンレターを送りませんか？'}
              onClick={handleClick}
            />
          </div>
          <StyledButton onClick={handleClick}>
            <StyledButtonText>デジタルファンレターを送る</StyledButtonText>
          </StyledButton>
        </StyledCard>
        <StyledCloseButton onClick={() => handleVisibleChanged(false)}>閉じる</StyledCloseButton>
      </StyledPopupContent>
    </StyledPopupWrapper>
  )
}

export default FanLetterEncouragementPopup
